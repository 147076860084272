/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import React, { FC, useState, useCallback } from 'react';
import { AxiosError } from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import classnames from 'classnames';
import { t } from '../../../i18n';
import styles from './styles.module.scss';
import { Checkbox } from '../../../components/inputs/Checkbox';
import { Modal } from '../../../components/main/Modal';
import { OrderConfirmModal } from '../../../components/order/OrderConfirmModal';
import { sendOrderRequest } from '../../../utils/api';
import { OrderSuccessModal } from '../../../components/order/OrderSuccessModal';

import {
  getGa,
  getUtmData,
} from '../../../components/directions/restocare/utils/ga';
import { OopsPanel } from '../../common/OopsPanel';
import { OrderForm } from '../../common/OrderForm';

const OrderMainForm: FC = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const formName = 'main_order_form';

  const [subscribe, setSubscribe] = useState(true);
  const [show_modal, setShowModal] = useState(false);
  const [show_success, setShowSuccess] = useState(false);
  const [show_oops, setShowOops] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formState, setFormState] = useState<Record<string, any>>({});

  const showModal = useCallback(() => setShowModal(true), [setShowModal]);
  const hideModal = useCallback(() => setShowModal(false), [setShowModal]);

  const showSuccess = useCallback(() => setShowSuccess(true), [setShowSuccess]);
  const hideSuccess = useCallback(
    () => setShowSuccess(false),
    [setShowSuccess]
  );

  const onSubmitForm = useCallback(
    async ({
      data,
      features,
      stack,
      prototypes,
      captcha,
      task,
      file,
      filename,
      design,
      characteristics,
      budget,
    }: Record<string, any>) => {
      const referrer = document?.referrer || '';
      const googleId = getGa();
      const utm = getUtmData();

      try {
        return await sendOrderRequest({
          ...data,
          subscribe,
          file,
          filename,
          features,
          captcha,
          stack,
          prototypes,
          task,
          design,
          characteristics,
          budget,
          ...utm,
          googleId,
          referrer,
        });
      } catch (e) {
        const error = e as AxiosError;
        setErrorMessage(error.response?.data.error);
        setShowOops(true);
      }
    },
    [subscribe, hideModal, showSuccess]
  );

  const onSubmitFirstStage = async (DATA: Record<string, string>) => {
    setFormState(DATA);
    showModal();

    try {
      if (!executeRecaptcha) return;
      const captcha = await executeRecaptcha(formName);
      await onSubmitForm({ data: DATA, captcha });
    } catch (e) {
      const error = e as AxiosError;
      setErrorMessage(error.response?.data.error);
      setShowOops(true);
    }
  };

  const onSubmitSecondStage = async (SECOND_DATA: Record<string, string>) => {
    try {
      if (!executeRecaptcha) return;
      const captcha = await executeRecaptcha(formName);
      const res = await onSubmitForm({
        data: formState,
        ...SECOND_DATA,
        captcha,
      });

      if (res) {
        showSuccess();
      }
    } catch (e) {
      const error = e as AxiosError;
      setErrorMessage(error.response?.data.error);
      setShowOops(true);
    } finally {
      hideModal();
      setFormState({});
    }
  };

  return (
    <>
      <OopsPanel isOpen={show_oops} errorMessage={errorMessage} />

      {show_modal && (
        <Modal onClose={hideModal}>
          <OrderConfirmModal onSubmit={onSubmitSecondStage} />
        </Modal>
      )}

      {show_success && <OrderSuccessModal onClose={hideSuccess} />}

      <div className={classnames('content', styles.content)}>
        <div className={styles.title}>
          <div className={styles.title__text}>
            <h1>{t('order.header')}</h1>
          </div>

          <div className={classnames(styles.title__subscribe)}>
            <Checkbox checked={subscribe} handler={setSubscribe} />
            {t('order.subscribe')}
          </div>
        </div>

        <OrderForm
          formId={formName}
          onSubmit={onSubmitFirstStage}
          actionButtonName="order.button"
          isFormSucces={show_success}
        />
      </div>
    </>
  );
};

export default OrderMainForm;

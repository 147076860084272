import React, { FC, useMemo } from 'react';
import { PageProps, graphql } from 'gatsby';
import { ReCaptchaProvider } from '../../components/common/ReCaptchaProvider';
import { MainLayout } from '../../templates/MainLayout';
import { t } from '../../i18n';
import { YellowHeader } from '../../components/main/YellowHeader';
import { IHeaderQuery } from '../../queries/headers/types';
import localeData from '../../i18n/locales/en';
import OrderMainForm from '../../components/order/OrderMainForm';

type IProps = PageProps<IHeaderQuery, { locale: string }>;

const OrderPage: FC<IProps> = ({
  location,
  data,
  pageContext: { locale = 'en' },
}) => {
  const header = useMemo(
    () => data.headers.edges.find((edge) => edge.node),
    [data.headers]
  );

  return (
    <ReCaptchaProvider>
      <MainLayout
        locale={locale}
        title={localeData.reviews.seo.title}
        description={localeData.reviews.seo.description}
        keywords={localeData.reviews.seo.keywords}
      >
        <YellowHeader header={t('order.title')}>
          {header?.node.html || ''}
        </YellowHeader>

        <OrderMainForm />
      </MainLayout>
    </ReCaptchaProvider>
  );
};

export const query = graphql`
  {
    headers: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/order/header/" } }
    ) {
      ...HeaderFields
    }
  }
`;

export default OrderPage;
